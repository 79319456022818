
.MuiOutlinedInput-multiline {
    padding: 0 !important; 
} 

.MuiFilledInput-multiline {
    padding: 0 !important;
}
.MuiSelect-outlined.MuiSelect-outlined {
    padding: 12px 10px !important;
}

.MuiFilledInput-input {
    padding: 12px 10px !important;
}

.MuiOutlinedInput-input {
    padding: 12px 10px !important;
}

input {
    color: black !important;
}
textarea {
    color: black !important;
}

.MuiSelect-select.MuiSelect-select {
    color:black !important
}